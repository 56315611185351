import React, { useMemo } from "react";

import ReactChartkick, { LineChart } from "react-chartkick";
import Chart from "chart.js";
import { UPDATE_CHARTS_updates_updates } from "./__generated__/UPDATE_CHARTS";

ReactChartkick.addAdapter(Chart);

type Props = { height?: number; updates: UPDATE_CHARTS_updates_updates[] };

type TimeSeries = { [date: string]: number };

export default function PrimaryMetricChart({ updates, ...props }: Props) {
  const updateMetrics = useMemo(() => {
    const metrics: { [name: string]: TimeSeries } = {};

    updates.forEach((u) => {
      metrics[u.metricDisplayName] = metrics[u.metricDisplayName] || {};
      metrics[u.metricDisplayName][u.createdAt] = u.metricValue;
    });

    return Object.entries(metrics).map(([name, timeSeries]) => ({ name, data: timeSeries }));
  }, [updates]);

  return <LineChart {...props} data={updateMetrics} />;
}
