import { useQuery, gql } from "@apollo/client";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";
import { Pane } from "../../components/Pane";
import { client } from "../../graphql";
import PrimaryMetricChart from "./PrimaryMetricChart";
import SecondaryMetricsChart from "./SecondaryMetricsChart";
import { UPDATE_CHARTS, UPDATE_CHARTSVariables } from "./__generated__/UPDATE_CHARTS";

const Title = styled.h4({
  textAlign: "center",
});

export default function UpdateCharts(props: { companyId?: string }) {
  const { data } = useQuery<UPDATE_CHARTS, UPDATE_CHARTSVariables>(
    gql`
      query UPDATE_CHARTS($companyId: ID) {
        updates(companyId: $companyId) {
          updates {
            createdAt
            metricDisplayName
            metricValue
            morale
            talkedTo
          }
        }
      }
    `,
    { variables: { companyId: props.companyId }, client }
  );

  return (
    <>
      <Pane css={css({ padding: 15 })}>
        <Title>Primary Metric</Title>
        {data?.updates && <PrimaryMetricChart height={200} updates={data.updates.updates} />}
      </Pane>
      <Pane css={css({ padding: 15 })}>
        <Title>Secondary Metrics</Title>
        {data?.updates && <SecondaryMetricsChart height={200} updates={data.updates.updates} />}
      </Pane>
    </>
  );
}
