import React from "react";
import { fromPairs } from "lodash";

import ReactChartkick, { LineChart } from "react-chartkick";
import Chart from "chart.js";
import { UPDATE_CHARTS_updates_updates } from "./__generated__/UPDATE_CHARTS";

ReactChartkick.addAdapter(Chart);

type Props = { height?: number; updates: UPDATE_CHARTS_updates_updates[] };

export default function SecondaryMetricsChart({ updates, ...props }: Props) {
  return (
    <LineChart
      {...props}
      colors={["green", "orange"]}
      data={[
        {
          name: "Morale",
          data: fromPairs(updates.map((u) => [u.createdAt, u.morale])),
        },
        {
          name: "Users contacted",
          data: fromPairs(updates.map((u) => [u.createdAt, u.talkedTo])),
        },
      ]}
    />
  );
}
